import { render, staticRenderFns } from "./ModalColaborador.vue?vue&type=template&id=563c404c"
import script from "./ModalColaborador.vue?vue&type=script&lang=js"
export * from "./ModalColaborador.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports