<script>
import { mapActions } from "vuex";
import moment from "moment";
import SectionTabs from "../Section/SectionTabs.vue";
import InputDate from "@/components/forms/InputDate.vue"
import ModalColaborador from "@/components/Recognize/ModalColaborador.vue"
import Loading from "@/components/Loading.vue";
import C3Paginate from "@/components/C3Paginate.vue";
import SinResultados from "@/components/SinResultados.vue";
export default {
  components: {
    SectionTabs,
    InputDate,
    ModalColaborador,
    Loading,
    SinResultados,
    C3Paginate
  },
  data() {
    return {
      img: require("@/assets/img/reconoze/ball-star.svg"),
      tab_component: [{
          name: "Dashboard",
          route: "recognize-Dashboard",
        },
        {
          name: "Indicadores",
          route: "recognize-contributor",
        },
      ],
      indicadores_list: [{
          label: "Año",
          route: "recognize-year"
        },
        {
          label: "Gerencia",
          route: "recognize-management"
        },
        {
          label: "Planta",
          route: "recognize-age"
        },
        {
          label: "Top 20",
          route: "recognize-ranking"
        },
        {
          label: "Consultas por colaborador",
          route: "recognize-contributor"
        },
        {
          label: "Detalles de reconocimiento",
          route: "recognize-reports"
        },
      ],
      indicadores_selected: "Consultas por colaborador",
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      title_component: {
        title: "Reconoce",
        subtitle: "¡Conoce el impacto de Reconoce! Podrás revisar cifras anuales, por gerencia, top 20, lugar de trabajo y colaborador.",
      },
      user_list: [],
      user_default_img: require("@/assets/img/user_default_img.svg"),
      pagination: {
        actual_page: 1
      },
      //FF
      loading: true,
      range: {
        date_start: new Date(`${moment.utc().startOf('month').format('YYYY-MM-DD')} 12:00:00`),
        date_end: new Date(`${moment.utc().endOf('month').format('YYYY-MM-DD')} 12:00:00`)
      },
      filter: {
        visibles: "1",
        search: "",
        pagina: 1,
        limite: "16"
      },
      selectedUser: {},
      usuario_reconocimientos: []
    };
  },
  watch: {
    "filter.search" (value) {
      if (value !== '') {
        this.findReconocimientosByUsername();
      }
    },
    "range.date_start" () {
      this.findReconocimientosByUsername();
    },
    "range.date_end" () {
      this.findReconocimientosByUsername();
    },
    indicadores_selected(value) {
      this.$router.push({
        name: value.route
      });
    },
  },

  computed: {
    selectedFilter() {
      return this.filter.visibles === "1" ? "Recibidos" : "Enviados";
    }
  },

  async created() {
    await this.findReconocimientosByUsername();
  },

  methods: {
    ...mapActions("recognizeModule", ["findReconocimientosByUsernameAction"]),
    async findReconocimientosByUsername() {
      this.loading = true;
      const payload = {
        nombre_usuario: this.filter.search,
        id_empresa: this.id_empresa,
        fecha_inicio: this.range.date_start ? moment(this.range.date_start).format('YYYY-MM-DD') : moment.utc().endOf('month').format('YYYY-MM-DD'),
        fecha_final: this.range.date_end ? moment(this.range.date_end).format('YYYY-MM-DD') : moment.utc().endOf('month').format('YYYY-MM-DD'),
        pagina: +this.pagination.actual_page
      };
      const { data, ...paginationData } = await this.findReconocimientosByUsernameAction(payload);
      this.user_list = data;
      this.pagination = { ...this.pagination, ...paginationData };
      if (!this.user_list) {
        this.toastGenericError();
      }
      this.loading = false;
    },
    setSelectedUser(user) {
      this.selectedUser = user;
    },
    onPageChange() {
      this.findReconocimientosByUsername();
    },
    totalReconocimientos(user) {
      if (this.filter.visibles === "1") {
        return user.reconocimientos_recibidos.length;
      } else if (this.filter.visibles === "2") {
        return user.reconocimientos_enviados.length;
      }
    },
    //FF
    captureDateStart(date) {
      this.range.date_start = date;
    },
    captureDateEnd(date) {
      this.range.date_end = date;
    }
  }
};
</script>

<template>
  <section id="contributor">
    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component" />
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12 section-head d-flex align-items-center">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          {{ title_component.subtitle }}
        </h3>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-lg-3 mb-3">
        <v-select class="selvue-custom" :options="indicadores_list" v-model="indicadores_selected" :clearable="false">
        </v-select>
      </div>
      <div class="col-12 col-lg-8 offset-lg-1 mb-3">
        <div class="row">
          <div class="col-12 col-lg-4 col-xl-6 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon icon="filter" class="color-secondary pe-1" /> Filtrar por periodo
          </div>
          <div class="col-12 col-lg-8 col-xl-6">
            <div class="row">
              <div class="col-6">
                <InputDate :initialDate="range.date_start" :max="range.date_end" placeHolder="Inicio" @dateEmit="captureDateStart" />
              </div>
              <div class="col-6">
                <InputDate :initialDate="range.date_end" :min="range.date_start" placeHolder="Término" @dateEmit="captureDateEnd" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-shadow border-0">
          <div class="card-body">
            <div class="row">
              <div class="col-6 input-search">
                <input type="search" class="form-control input-custom" placeholder="Buscar colaborador" v-model.trim="filter.search" @keydown.enter="findReconocimientosByUsername()" />
                <span class="input-search-icon" @click="findReconocimientosByUsername()">
                        <font-awesome-icon icon="search"/>
                      </span>
              </div>
              <div class="col-6 text-secondary d-flex align-items-center justify-content-end">
                <div class="btn-group btn-group-custom btn-group-custom-shadow" role="group">
                  <input value="1" v-model="filter.visibles" type="radio" class="btn-check btn-group-custom-check" name="filtro-noticias" id="radio-recibidos" autocomplete="off" checked>
                  <label class="btn btn-group-custom-label" for="radio-recibidos">
                      Recibidos
                    </label>
                  <input value="2" v-model="filter.visibles" type="radio" class="btn-check btn-group-custom-check" name="filtro-noticias" id="radio-enviados" autocomplete="off">
                  <label class="btn btn-group-custom-label" for="radio-enviados">
                      Enviados
                    </label>
                </div>
              </div>
            </div>
            <div class="border-bottom pb-3"></div>
            <Loading v-if="loading" pixeles="150px" />
            <template v-else>         
                <div class="row">
                  <div class="col-12">
                    <div class="list-group list-group-flush">
                      <a
                        v-for="(user, k) in user_list" :key="k" 
                        @click="setSelectedUser(user)"
                        href="javascript:"
                        data-bs-toggle="modal" 
                        data-bs-target="#modal-colaboradores"
                        class="list-group-item list-group-item-action"
                      >
                        <div class="row p-1">
                          <div class="col-12 col-lg-5">
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0">
                                <img 
                                  v-if="user.imagen_perfil" 
                                  width="60"
                                  height="60"
                                  class="img-object-cover rounded-circle"
                                  :src="user.imagen_perfil" 
                                />
                                <img 
                                  v-else
                                  width="60"
                                  height="60"
                                  class="img-object-cover rounded-circle"
                                  :src="user_default_img" 
                                />
                              </div>
                              <div class="flex-grow-1 ms-3">
                                <h6 class="color-secondary font-700">
                                  {{ capitalizeFirstLetter(user.primer_nombre) }} 
                                  {{ capitalizeFirstLetter(user.apellido_paterno) }}
                                </h6>
                                <p class="text-secondary mb-0">
                                  {{ !user.cargo || !user.cargo.nombre_cargo ? '' : capitalizeFirstLetter(user.cargo.nombre_cargo) }}
                                  <!-- <br> -->
                                  <!-- <img :src="img" width="25" height="25"/> Total: {{ totalReconocimientos(user) }} -->
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 d-flex align-items-center align-content-end justify-content-start gap-2 flex-wrap">
                            <img 
                              :src="img" 
                              width="50" height="50" 
                            /> 
                            <h5> {{ selectedFilter }}: {{ totalReconocimientos(user) }} </h5>

                            <!-- <span 
                              v-for="(item, i) in user.reconocimientos_valor" :key="i"
                              class="border border-2 text-secondary rounded-4 p-2"
                            > 
                              <span> {{ item.nombre_valor }}: </span> {{ item.cantidad }}
                            </span> -->
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </template>
            
            <SinResultados v-if="!loading && user_list.length === 0"/>
            
          </div>
        </div>
        <c3-paginate 
          :pagination="pagination" 
          @pageChanged="onPageChange"
        />
      </div>
    </div>
  <ModalColaborador 
    :user="selectedUser"
    :tipoReconocimiento="filter.visibles"
  />

</section>
</template>