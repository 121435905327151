<script>
export default {
	props: {
		user: Object,
		tipoReconocimiento: String
	},

	computed: {
		reconocimientos() {
			if (this.tipoReconocimiento === "1") {
				return this.user.reconocimientos_recibidos;
			} else {
				return this.user.reconocimientos_enviados;
			}
		},
		reconocimientosAgrupados() {
			const counts = {};
			this.reconocimientos.forEach(rec => {
				rec.valor_comportamiento.forEach(valor => {
					const key = valor.valor.nombre_valor;
					if (counts[key]) {
						counts[key].cantidad += 1;
					} else {
						counts[key] = {
							cantidad: 1,
							color: valor.valor.color
						};
					}
				});
			});
			return Object.entries(counts).map(([nombre, data]) => ({
				nombre,
				cantidad: data.cantidad,
				color: data.color
			}));
		}
	}
}
</script>
<template>
	<div 
		class="modal fade" 
		id="modal-colaboradores" 
		tabindex="-1" 
		aria-hidden="true"
	>
		<div class="modal-dialog modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title text-secondary">
						<img 
								v-if="user.profile_picture" 
								:src="user.profile_picture" 
								width="48" 
								height="48" 
								class="img-object-cover rounded-circle border" 
						/>
						<img 
								v-else
								src="@/assets/img/user_default_img.svg" 
								width="48" 
								height="48" 
								class="img-object-cover rounded-circle border" 
						/>
						<span v-if="Object.keys(user).length !== 0">
							{{ capitalizeFirstLetter(user.primer_nombre) }} 
							{{ capitalizeFirstLetter(user.apellido_paterno) }}
						</span>
					</h4>
					<button 
						type="button" 
						class="modal-custom-close"
						data-bs-dismiss="modal" 
						aria-label="Close"
					>
						<font-awesome-icon icon="xmark"/>
					</button>
				</div>
				

				
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
							<h5 class="font-main text-secondary">
								<img width="35" height="35" src="@/assets/img/reconoze/ball-star.svg">
								<span v-if="tipoReconocimiento === '1'">Recibidos</span>
								<span v-if="tipoReconocimiento === '2'">Enviados</span>
							</h5>
							<template v-if="reconocimientosAgrupados.length !== 0">
								<ul class="list-group list-group-flush">

										<ul class="list-group list-group-flush">
											<li v-for="(item, index) in reconocimientosAgrupados" :key="index" class="list-group-item">
												<div class="d-flex justify-content-between align-items-center">

													<span class="font-500" :style="`color: #${item.color};`">{{ item.nombre }}</span>
													<span class="badge bg-primary rounded-pill">{{ item.cantidad }}</span>
												</div>
											</li>
										</ul>
								</ul>
							</template>
							<template v-else>
								<div class="alert alert-info text-center mb-0">
									<i class="fa-solid fa-circle-exclamation"></i>
									<span v-if="tipoReconocimiento === '1'">
										Aún no se han recibido <strong>reconocimientos</strong>
									</span>
									<span v-if="tipoReconocimiento === '2'">
										Aún no se han enviado <strong>reconocimientos</strong>
									</span>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>